<template>
  <vue-final-modal class="modal-cancel-active-bonus">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('cancel-active-bonus')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="modal-cancel-active-bonus__description">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </div>

      <card-cash-bonus v-if="activePlayerBonus" :bonusInfo="activePlayerBonus" hideButton />

      <div class="modal-cancel-active-bonus__actions">
        <button-base type="secondary-3" size="md" @click="closeModal('cancel-active-bonus')">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'cancelButton') }}
        </button-base>

        <button-base type="secondary-1" size="md" :isDisabled="bonusProcessing" @click="cancelBonus">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'confirmButton') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CICancelActiveBonusModal } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CICancelActiveBonusModal>;
    defaultLocaleData: Maybe<CICancelActiveBonusModal>;
  }>();

  const { getContent } = useProjectMethods();
  const { closeModal } = useModalStore();
  const bonusStore = useBonusStore();
  const { processingBonuses } = storeToRefs(bonusStore);
  const { activePlayerBonus } = bonusStore;
  const { alertsData, defaultLocaleAlertsData } = useGlobalStore();
  const { showAlert } = useLayoutStore();

  const bonusProcessing = computed(() => processingBonuses.value.includes(activePlayerBonus?.id || '-1'));
  const { cancelPlayerBonus } = useCoreBonusApi();
  const cancelBonus = async (): Promise<void> => {
    if (bonusProcessing.value || !activePlayerBonus?.id) return;
    processingBonuses.value.push(activePlayerBonus.id);

    try {
      await cancelPlayerBonus(activePlayerBonus.id);
      processingBonuses.value = processingBonuses.value.filter(id => id !== activePlayerBonus.id);
      await closeModal('cancel-active-bonus');
    } catch {
      processingBonuses.value = processingBonuses.value.filter(id => id !== activePlayerBonus.id);
      showAlert(alertsData?.global?.somethingWrong || defaultLocaleAlertsData?.global?.somethingWrong);
    }
  };
</script>

<style src="~/assets/styles/components/modal/cancel-active-bonus.scss" lang="scss" />
